import { useAbility } from '@casl/react'
import { Space, Typography, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { MeContext, UserPermissionFeature, useFeaturedBasketsQuery } from '../../../api'
import { AbilityContext } from '../../../api/components/Can'
import { BasketListItem } from '../../Baskets/components/BasketListItem'
import { HomeCarousel } from '../components/HomeCarousel'
import { HomeSection } from '../components/HomeSection'
import { useCreateSupportTicketMutation } from '../../../api/hooks/support-ticket'
import { CreateItemCard } from '../components/CreateItemCard'

const SUPPORT_TICKET_KEY = 'home_baskets_st_requested'

export const CategoriesSection: React.FC = () => {
  const { data: meData } = useContext(MeContext)
  const ability = useAbility(AbilityContext)
  const hasPermission = ability.can('access', UserPermissionFeature.Baskets)

  const userSupportTicketKey = `${SUPPORT_TICKET_KEY}_${meData?.me.user.id}`
  const [requested, setRequested] = useState(false)

  const { data, loading } = useFeaturedBasketsQuery({
    variables: {
      pagination: {
        limit: 9,
        offset: 0,
      },
    },
    skip: !hasPermission,
    fetchPolicy: 'network-only',
  })
  const [createSupportTicket, { loading: ticketLoading }] = useCreateSupportTicketMutation({
    onCompleted: () => {
      window.localStorage.setItem(userSupportTicketKey, Date.now().toString())
      setRequested(true)
      message.success(
        'We acknowledged your request. Customer Success team will contact you to provide more information.',
      )
    },
    onError: () => {
      message.error('Your request was failed. Please check your network or contact our support to help you fix it.')
    },
  })

  useEffect(() => {
    setRequested(!!window.localStorage.getItem(userSupportTicketKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomeSection
      title={
        <Space direction="horizontal">
          <Typography.Title level={4}>Market Baskets</Typography.Title>
          <Typography.Text style={{ fontSize: '20px' }}>(75th)</Typography.Text>
        </Space>
      }
      showAllLink={hasPermission && data?.featuredBaskets.length ? '/analytics/baskets' : undefined}>
      <HomeCarousel
        loading={loading}
        items={hasPermission ? data?.featuredBaskets || [] : []}
        renderItem={({ basket, featuredTag }) => (
          <BasketListItem basket={basket} featuredTag={featuredTag} targetPercentile={75} />
        )}
        slidesToShow={3}
        extraItem={
          !hasPermission || !data?.featuredBaskets.length ? (
            <CreateItemCard
              title="Load Your Data"
              description={
                requested
                  ? 'We acknowledged your request. Customer Success team will contact you to provide more information.'
                  : 'Add spend data to Curvo to identify opportunities and track savings with flexible categorization.'
              }
              loading={ticketLoading}
              requested={requested}
              onClick={() => {
                createSupportTicket({ variables: { input: { subject: 'Request to add new Market Basket on CX' } } })
              }}
            />
          ) : null
        }
      />
    </HomeSection>
  )
}
